import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import { useCaseStudies } from 'content-queries/mx/new/case-studies'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { MainContent } from 'mx/components/new'
import { ContentColumnCarousel } from 'mx/components/new/content-column-carousel'
import Layout from 'mx/layout'

const CaseStudiesPage = ({ path }: { path: string }) => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Case Studies',
    })
  }, [])

  const { data } = useCaseStudies()

  const [
    {
      node: {
        sectionModules: [
          hero,
          battle,
          tustin,
          nantucket,
          pond,
          bothell,
          provo,
          banner,
        ],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <MainContent data={hero} header />
      <ContentColumnCarousel
        isCaseStudies
        data={battle}
        classname={tw`mt-20 lg:mt-32`}
      />
      <ContentColumnCarousel
        isCaseStudies
        data={tustin}
        classname={tw`mt-20 lg:mt-32`}
      />
      <ContentColumnCarousel
        isCaseStudies
        data={nantucket}
        classname={tw`mt-20 lg:mt-32`}
      />
      <ContentColumnCarousel
        isCaseStudies
        data={pond}
        classname={tw`mt-20 lg:mt-32`}
      />
      <ContentColumnCarousel
        isCaseStudies
        data={bothell}
        classname={tw`mt-20 lg:mt-32`}
      />
      <ContentColumnCarousel
        isCaseStudies
        data={provo}
        classname={tw`mt-20 lg:mt-32`}
      />
      <MainContent data={banner} classname={tw`mt-20 lg:mt-32`} />
    </Layout>
  )
}

export default CaseStudiesPage

export const Head = () => {
  const { data } = useCaseStudies()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/case-studies"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
